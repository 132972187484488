(function($) {
"use strict";

/*------------------------------------------------------------------
[Table of contents]

LP MAIN MENU INIT
LP TESTIMONIAL INIT
LP EXTRA MENU INIT
LP GALLERY FILTER INIT
LP GOOGLE MAP INIT

-------------------------------------------------------------------*/

/*--------------------------------------------------------------
CUSTOM PRE DEFINE FUNCTION
------------------------------------------------------------*/
/* is_exist() */
jQuery.fn.is_exist = function(){
  return this.length;
}


$(function(){


/*--------------------------------------------------------------
OS MENU JS
--------------------------------------------------------------*/

$(".menu-bar").on("click", function () {
    $(".offcanves-menu, .offcanvas-overlay").addClass("active");
});
$(".close, .offcanvas-overlay").on("click", function () {
    $(".offcanves-menu, .offcanvas-overlay").removeClass("active");
});



});/*End document ready*/


$(window).on("resize", function(){

}); // end window resize


$(window).on("load" ,function(){

// (function($) {

	"use strict";
  // console.log('LOOOOADED!!');

  window.scrollTo({top: 0, behavior: 'smooth'});
	var fullHeight = function() {

		$('.js-fullheight').css('height', $(window).height());
		$(window).resize(function(){
			$('.js-fullheight').css('height', $(window).height());
		});

	};
	fullHeight();

	$('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
  // });

});
}); // End window LODE





})(jQuery);






